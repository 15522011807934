/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useFormState } from 'react-hook-form';
import { AppButtonsBar } from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppIcons } from 'src/business/_core/modules/layout/icons';
import { AppDocEditDialogLocalState } from './useAppDocEditDialogLocalState';

export const AppDocEditDialogActionsBar = ({
  localState,
  closeDialog,
}: {
  localState: AppDocEditDialogLocalState;
  closeDialog: () => void;
}) => {
  const form = localState.state.form;

  const formState = useFormState({
    control: localState.state.form.control,
  });
  return (
    <AppButtonsBar hasChanges={true}>
      <>
        <AppButton
          size="normal"
          icon={AppIcons.action.cancel}
          tabIndex={500}
          color="gray-outline-light"
          onClick={async () => {
            await localState.actions.cancelChanges();
          }}
        >
          Annuler
        </AppButton>
        {localState.state.mode === 'edit' && (
          <AppButton
            type="button"
            size="normal"
            icon={AppIcons.action.delete}
            color="danger-outline-light"
            onClick={async () => {
              await localState.actions.confirmDelete();
            }}
          >
            Supprimer
          </AppButton>
        )}
        <AppButton
          type="button"
          size="normal"
          icon={AppIcons.action.save}
          color="primary-outline-light"
          disabled={localState.state.operationInProgressMessage !== undefined}
          onClick={async () => {
            form.handleSubmit(
              async () => {
                await localState.actions.persistChanges();
              },
              (err) => {
                // affichager
                console.log('xxx err:', err);
              },
            )();
          }}
        >
          Confirmer
        </AppButton>
      </>
    </AppButtonsBar>
  );
};
