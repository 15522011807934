/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubDiverFull,
  ClubDiverSelectorDialogCreateDiverFormModel,
} from '@mabadive/app-common-model';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  AppFixedButtonsBar,
  AppPageContentContainer,
  useAutoFocus,
  useMediaSizeTailwind,
} from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useGlobalClasses } from 'src/business/_core/modules/root/theme';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { SelectDiverComponent } from 'src/business/club/modules/_common/form';
import { ClubDialogsState } from 'src/pages/_dialogs';
import {
  BookingParticipantEditorDiverMember,
  BookingParticipantEditorResult,
} from '../../models';
import { ClubDiverSelectorDialogCreateDiverMemberForm } from './ClubDiverSelectorDialogCreateDiverMemberForm';
import { ClubDiverSelectorDialogCreateManyDiversMemberForm } from './ClubDiverSelectorDialogCreateManyDiversMemberForm';
import { SelectedDiversTable } from './SelectedDiversTable';
import {
  ClubDiverSelectorDialogAppMessageInstructions,
  ClubDiverSelectorDialogMultiDiversCheckbox,
  ClubDiverSelectorDialogSubDialogsProvider,
} from './components';
import { memberCreatorChangeBuilder } from './memberCreatorChangeBuilder.service';
import {
  ClubDiverSelectorDialogInputState,
  ClubDiverSelectorDialogMode,
  ClubDiverSelectorDialogUpdateState,
} from './model';
import {
  ClubDiverSelectorDialogGlobalState,
  useClubDiverSelectorDialogGlobalState,
} from './useClubDiverSelectorDialogGlobalState.hook';
import { useClubDiverSelectorDialogSubDialogs } from './useClubDiverSelectorDialogSubDialogs.hook';

const DEFAULT_FORM_VALUE: ClubDiverSelectorDialogCreateDiverFormModel = {
  diver: {},
  massiveCreation: {
    enabled: false,
    quantity: 5,
    firstNumber: 1,
  },
};

export const BookingPageMemberCreatorDialog: FC<{
  dialogsState: ClubDialogsState;
  state: ClubDiverSelectorDialogInputState;
  setState: React.Dispatch<
    React.SetStateAction<ClubDiverSelectorDialogInputState>
  >;
  onConfirm?: (updateState: ClubDiverSelectorDialogUpdateState) => void;
  onCancel?: () => void;
}> = ({
  dialogsState,
  onConfirm = () => {},
  onCancel = () => {},
  state: inputState,
  setState,
}) => {
  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const autoFocus = useAutoFocus();
  const bookingId = inputState.booking._id;

  const globalClasses = useGlobalClasses();

  const dialogs = useClubDiverSelectorDialogSubDialogs();

  const { setBookingParticipantEditorState } = dialogs;

  const [mode, setMode] =
    useState<ClubDiverSelectorDialogMode>('single-divers');

  const [massiveModeEnabled, setMassiveModeEnabled] = useState<boolean>(false);

  const [diversIdsToIgnore, setDiversIdsToIgnore] = useState(
    inputState.diversIdsToIgnore,
  );
  const [diversToSuggest, setDiversToSuggest] = useState(
    inputState.diversToSuggest,
  );

  const globalState: ClubDiverSelectorDialogGlobalState =
    useClubDiverSelectorDialogGlobalState({
      inputState,
    });

  const { aggregatedData, updateState, setUpdateStateInner, setUpdateState } =
    globalState;

  const { existingBookingMembersFull, newBookingMembers } = aggregatedData;

  const autoFillLastName = useMemo(
    () =>
      existingBookingMembersFull.find(
        (x) => x.booking.bookingContactDiverId === x.diver._id,
      )?.diver?.lastName,
    [existingBookingMembersFull],
  );

  const form = useForm<ClubDiverSelectorDialogCreateDiverFormModel>({
    defaultValues: DEFAULT_FORM_VALUE,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const formValue = useWatch({
    control,
  });

  const hasFormChanges = useMemo(
    () =>
      form &&
      (formState.isDirty || formState.isValid) &&
      formValue?.diver &&
      Object.values(formValue.diver).filter((x) => !!x).length !== 0,
    [form, formState.isDirty, formState.isValid, formValue.diver],
  );

  const updateBookingParticipantEditor = useCallback(
    (result: BookingParticipantEditorResult) => {
      const updateStateLocal =
        memberCreatorChangeBuilder.updateDiversAndMembers({
          updateState,
          result,
        });

      setUpdateState(updateStateLocal);
    },
    [setUpdateState, updateState],
  );

  const closeDialog = useCallback(() => {
    setState({
      isOpen: false,
    });
  }, [setState]);

  const createDiverAndMember = useCallback(() => {
    handleSubmit(
      (formValue: ClubDiverSelectorDialogCreateDiverFormModel, event) => {
        const updateStateLocal =
          memberCreatorChangeBuilder.createDiverAndMember({
            bookingId,
            clubReference,
            diveCenterId,
            formValue,
            updateState,
          });

        if (mode === 'single-divers') {
          onConfirm(updateStateLocal);
        } else {
          setUpdateState(updateStateLocal);
          form.reset(DEFAULT_FORM_VALUE);
        }
      },
      (err) => {
        appLogger.error('Unexpected error', err);
      },
    )();
  }, [
    bookingId,
    clubReference,
    diveCenterId,
    form,
    handleSubmit,
    mode,
    onConfirm,
    setUpdateState,
    updateState,
  ]);

  const onSelectDiver = useCallback(
    (clubDiverFull: ClubDiverFull) => {
      const updateStateLocal = memberCreatorChangeBuilder.addDiverAsMember({
        bookingId,
        clubDiverFull,
        clubReference,
        diveCenterId,
        updateState,
      });

      if (mode === 'single-divers') {
        onConfirm(updateStateLocal);
      } else {
        setUpdateState(updateStateLocal);

        setDiversIdsToIgnore(diversIdsToIgnore.concat([clubDiverFull._id]));
        const diverToSuggestIndex = diversToSuggest.findIndex(
          (d) => d._id === clubDiverFull._id,
        );
        if (diverToSuggestIndex !== -1) {
          const updatedDiversToSuggest = diversToSuggest.filter(
            (x, i) => i !== diverToSuggestIndex,
          );

          setDiversToSuggest(updatedDiversToSuggest);
        }
      }
    },
    [
      bookingId,
      clubReference,
      diveCenterId,
      diversIdsToIgnore,
      diversToSuggest,
      mode,
      onConfirm,
      setUpdateState,
      updateState,
    ],
  );

  const mediaSize = useMediaSizeTailwind();

  return (
    <>
      <div className={`h-full ${inputState.isOpen ? '' : 'hidden'}`}>
        <ClubDiverSelectorDialogSubDialogsProvider
          dialogsState={dialogsState}
          dialogs={dialogs}
          onBookingParticipantEditorConfirm={(
            result: BookingParticipantEditorResult,
          ) => {
            updateBookingParticipantEditor(result);
          }}
        >
          <AppPageContentContainer className="bg-white mx-1">
            <div className="my-3 flex gap-4 text-xs sm:text-sm">
              <ClubDiverSelectorDialogMultiDiversCheckbox
                mode={mode}
                setMode={setMode}
              />
              {/* <div
                className="cursor-pointer"
                onClick={() => {
                  if (!massiveModeEnabled) {
                    reset(
                      {
                        diver: {
                          firstName: 'P',
                        } as any,
                        massiveCreation: {
                          ...massiveCreation,
                          enabled: true,
                        },
                      },
                      {
                        keepDirty: true,
                      },
                    );
                  } else {
                    reset(
                      {
                        diver: {} as any,
                        massiveCreation: {
                          ...massiveCreation,
                          enabled: false,
                        },
                      },
                      {
                        keepDirty: true,
                      },
                    );
                  }
                  setMassiveModeEnabled(!massiveModeEnabled);
                }}
              >
                <AppInputBoolean type="checkbox" value={massiveModeEnabled} />
                <span
                  className={`ml-1 text-app-blue ${
                    mode === 'multi-divers' ? 'font-bold' : ''
                  }`}
                >
                  création en masse
                </span>
              </div> */}
            </div>
            {!massiveModeEnabled && (
              <SelectDiverComponent
                className="my-5"
                searchSuggestions={inputState.searchSuggestions}
                placeholder={
                  mediaSize === 'xxs' ||
                  mediaSize === 'xs' ||
                  mediaSize === 'sm'
                    ? 'NOM ou PRENOM'
                    : 'Recherche par NOM ou PRÉNOM'
                }
                autoFocus={autoFocus}
                diversIdsToIgnore={diversIdsToIgnore}
                diversToSuggest={diversToSuggest}
                onChange={(diver) => {
                  // diverComponent.updateAttributeValue(undefined, 'external');
                  if (diver) {
                    onSelectDiver(diver);
                  }
                }}
              />
            )}
            {!massiveModeEnabled && (
              <ClubDiverSelectorDialogCreateDiverMemberForm
                showConfirmButton={true}
                className="my-5"
                form={form}
                autoFillLastName={autoFillLastName}
                onSubmit={() => createDiverAndMember()}
                isDiverWithMember={true}
              />
            )}
            {massiveModeEnabled && (
              <ClubDiverSelectorDialogCreateManyDiversMemberForm
                className="my-5"
                form={form}
                autoFillLastName={autoFillLastName}
                onSubmit={() => createDiverAndMember()}
              />
            )}

            {newBookingMembers.length +
              (existingBookingMembersFull?.length ?? 0) !==
              0 && (
              <SelectedDiversTable
                className="mt-5 mb-12 border border-gray-400"
                existingBookingMembersFull={existingBookingMembersFull}
                newBookingMembers={newBookingMembers}
                onClickMember={(bookingMemberFull) => {
                  const participant: BookingParticipantEditorDiverMember = {
                    bookingId: bookingMemberFull.booking._id,
                    diver: bookingMemberFull.diver,
                    bookingMember: bookingMemberFull.bookingMember,
                    docResumes: bookingMemberFull.docResumes,
                    inquiryResponses: bookingMemberFull.inquiryResponses,
                    bookingSessionId: undefined,
                    diveSession: undefined,
                  };
                  setBookingParticipantEditorState({
                    isOpen: true,
                    mode: 'edit-diver',
                    participant,
                  });
                }}
              />
            )}
            {aggregatedData.newBookingMembers.length === 0 && (
              <ClubDiverSelectorDialogAppMessageInstructions
                multiSelectionModeAllowed={true}
              />
            )}
            <div className="mb-8" />
            {/* 'mb-8' pour être sûr que le contenu apparait en bas, sur ipad - TODO mutualiser tout ça proprement dans un composant */}
          </AppPageContentContainer>
          <div className="flex-grow" />
          <AppFixedButtonsBar hasChanges={true}>
            <>
              <AppButton
                size="normal"
                icon={AppHeroIcons.actionCancel}
                tabIndex={500}
                color="gray-outline-light"
                onClick={() => {
                  if (hasFormChanges) {
                    form.reset(DEFAULT_FORM_VALUE);
                  } else {
                    onCancel();
                    closeDialog();
                  }
                }}
              >
                {hasFormChanges || updateState.hasChanges
                  ? 'Annuler'
                  : 'Fermer'}
              </AppButton>
              {hasFormChanges && (
                <AppButton
                  disabled={!formState.isValid}
                  size="normal"
                  icon={AppHeroIcons.actionAdd}
                  color="primary-outline-light"
                  onClick={() => {
                    createDiverAndMember();
                  }}
                >
                  Ajouter
                </AppButton>
              )}
              {!hasFormChanges && updateState.hasChanges && (
                <AppButton
                  size="normal"
                  icon={AppHeroIcons.actionSave}
                  color="primary-outline-light"
                  onClick={() => {
                    onConfirm(updateState);
                  }}
                >
                  Confirmer
                </AppButton>
              )}
            </>
          </AppFixedButtonsBar>
        </ClubDiverSelectorDialogSubDialogsProvider>
      </div>
    </>
  );
};
