/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useClubResume } from 'src/business/club/data/hooks';
import { AppInputBooleanSwitchRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { BookingResumeMembersInquiryBadge } from '../../../DiverBookingCard/BookingResumeMembersTable/BookingResumeMembersInquiryBadge';
import { BookingCustomerConfigEditDialogLocalState } from '../../useBookingCustomerConfigEditDialogLocalState.hook';
import { BookingCustomerConfigEditForm_Inquiries_TableRow } from './BookingCustomerConfigEditForm_Inquiries_TableRow';

export const BookingCustomerConfigEditForm_Inquiries = ({
  localState,
}: {
  localState: BookingCustomerConfigEditDialogLocalState;
}) => {
  const { state, data, actions } = localState;
  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    state.form;

  const clubResume = useClubResume();
  const customerSettings = clubResume?.clubSettings?.customer;
  const clubReference = clubResume.reference;

  const isDiversPostalAddressInfoEnabledByDefault =
    clubReference === 'la-bulle-d-her-noirmoutier-27455';

  const formPrefix = 'booking.bookingCustomerConfig' as const;

  const [
    customerUiEnableCustomerUpdate,
    customerUiPendingListDiversContactInfo,
    customerUiPendingListDiversDivingInfo,
    customerUiPendingListDiversDiversPostalAddressInfo,
    customerUiPendingListDiversExpectedDiveInfo,
  ] = useWatch({
    control,
    name: [
      `${formPrefix}.customerUi.enableCustomerUpdate`,
      `${formPrefix}.pendingList.diversContactInfo`,
      `${formPrefix}.pendingList.diversDivingInfo`,
      `${formPrefix}.pendingList.diversPostalAddressInfo`,
      `${formPrefix}.pendingList.diversExpectedDiveInfo`,
    ],
  });

  const customerUpdateRequested =
    customerUiPendingListDiversContactInfo ||
    customerUiPendingListDiversDivingInfo ||
    customerUiPendingListDiversDiversPostalAddressInfo ||
    customerUiPendingListDiversExpectedDiveInfo;
  useEffect(() => {
    if (!customerUpdateRequested && customerUiEnableCustomerUpdate) {
      setValue(`${formPrefix}.customerUi.enableCustomerUpdate`, null);
    }
  });

  const bookingMembersConfigsFieldsArray = useFieldArray({
    control,
    name: 'bookingMembersConfigs',
  });
  return data.enabledInquiries?.length > 0 ? (
    <div>
      <AppFormControlRHF
        className={'md:col-span-4 w-full'}
        label="Questionnaires à remplir"
        labelFontSize="text-md"
        helpDescription="En activant cette option, vous autorisez le client à renseigner lui-même les informations de ses plongeurs."
        control={control}
        name={`${formPrefix}.customerUi.enableCustomerUpdate`}
        renderComponent={(props) => (
          <AppInputBooleanSwitchRHF
            {...props}
            theme="danger"
            label="Faire remplir un questionnaire par les participants"
            onChange={(value) => {
              if (value && !customerUpdateRequested) {
                setValue(`${formPrefix}.pendingList.diversContactInfo`, true);
                setValue(`${formPrefix}.pendingList.diversDivingInfo`, true);
                setValue(
                  `${formPrefix}.pendingList.diversPostalAddressInfo`,
                  isDiversPostalAddressInfoEnabledByDefault,
                );
                setValue(
                  `${formPrefix}.pendingList.diversExpectedDiveInfo`,
                  false,
                );
              }
            }}
          />
        )}
      />
      <table className="my-4 app-table">
        <thead>
          <tr className="bg-white text-gray-600 uppercase">
            <th className="px-2">Participant</th>
            <th className="px-2">Prochaines sorties</th>
            {data.enabledInquiries.map((x) => (
              <th className="px-2" key={x._id}>
                <BookingResumeMembersInquiryBadge
                  key={x._id}
                  inquiry={x}
                  status="ok"
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {bookingMembersConfigsFieldsArray.fields.map(
            (bookingMembersConfigsField, bookingMembersConfigsFieldIndex) => (
              <BookingCustomerConfigEditForm_Inquiries_TableRow
                key={bookingMembersConfigsFieldIndex}
                bookingMembersConfigsField={bookingMembersConfigsField}
                bookingMembersConfigsFieldIndex={
                  bookingMembersConfigsFieldIndex
                }
                localState={localState}
              />
            ),
          )}
        </tbody>
      </table>
    </div>
  ) : null;
};
