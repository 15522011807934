import {
  AppEntityUpdatePatch,
  Booking,
  BookingCustomerConfig,
  BookingCustomerSpacePrivate,
  BookingMemberConfig,
  CustomerSpaceDiverLink,
  DiveMode,
  ProMultiOperationPayload,
} from '@mabadive/app-common-model';
import {
  appInquiryFilter,
  appUrlBuilderCore,
  diverBookingBuilder,
  jsonPatcher,
  jsonPatcherSmart,
} from '@mabadive/app-common-services';
import { useCallback, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { proMultiMutationsApiClient } from 'src/business/_core/data/app-operation';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppTabTailwind,
  useAutoFocus,
} from 'src/business/_core/modules/layout';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { uiStore } from 'src/business/_core/store';
import { useClubResume } from 'src/business/club/data/hooks';
import {
  ClubDialogsState,
  UseClubDialogsProps,
  useClubDialogs,
  useClubDialogsActionsPersist,
} from 'src/pages/_dialogs';
import {
  PRO_BookingMemberFull,
  PRO_BookingParticipantFull,
} from '../../models';
import {
  BookingCustomerConfigEditDialogInputState,
  BookingCustomerConfigEditFormModel,
  BookingCustomerConfigTabId,
} from './_model';

export type BookingCustomerConfigEditDialog_Changes = {
  bookingPatch?: AppEntityUpdatePatch;
  bookingMembersPatches: AppEntityUpdatePatch[];
};

export function useBookingCustomerConfigEditDialogLocalState({
  inputState,
  setInputState,
  submitChanges,
}: {
  inputState: BookingCustomerConfigEditDialogInputState;
  setInputState: React.Dispatch<
    React.SetStateAction<BookingCustomerConfigEditDialogInputState>
  >;
  submitChanges?: (
    changes: BookingCustomerConfigEditDialog_Changes,
    { persistNow }: { persistNow: boolean },
  ) => Promise<void>;
}) {
  const isNewBooking = inputState?.isNewBooking;
  const bookingId = inputState?.booking?._id;
  const bookingContact = inputState?.bookingContact;

  const bookingMembersFull: PRO_BookingMemberFull[] =
    inputState.bookingMembersFull;
  const bookingParticipantsFull: PRO_BookingParticipantFull[] =
    inputState.bookingParticipantsFull;

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      setInputState({
        ...inputState,
        isOpen,
      });
    },
    [setInputState, inputState],
  );

  const clubResume = useClubResume();
  const clubReference = clubResume?.reference;

  const [tab, setTab] = useState<BookingCustomerConfigTabId>('tab-config');
  const [initialBooking, setInitialBooking] = useState(inputState.booking);
  const autoFocus = useAutoFocus();
  const redirectTo = useRedirect();

  const defaultBookingCustomerSpace =
    diverBookingBuilder.createDefaultBookingCustomerSpace({
      publicSettings: clubResume.clubSettings.publicSettings,
    });

  const enabledInquiries = useMemo(
    () =>
      clubResume.clubSettings?.general?.inquiry?.enabled
        ? (clubResume.inquiries ?? []).filter(
            (inquiry) => inquiry.settings?.enabled,
          )
        : [],
    [clubResume.clubSettings?.general?.inquiry?.enabled, clubResume.inquiries],
  );

  const initialBookingMembersConfigs = useMemo(() => {
    const bookingMembersConfigs: {
      bookingMemberFull: PRO_BookingMemberFull;
      config: BookingMemberConfig;
    }[] = bookingMembersFull.map((bookingMemberFull) => {
      const diveModes = bookingParticipantsFull
        .filter((x) => x?.diver?._id === bookingMemberFull.diver?._id)
        .reduce((acc, bpf) => {
          if (!acc.includes(bpf?.diveSessionParticipant?.diveMode)) {
            acc.push(bpf?.diveSessionParticipant?.diveMode);
          }
          return acc;
        }, [] as DiveMode[]);
      const nextDiveModes = [...new Set(diveModes)];
      const activeInquiries = appInquiryFilter.findActiveInquiries(
        clubResume.inquiries,
        {
          nextDiveModes,
          diver: bookingMemberFull.diver,
          bookingMemberConfigInquiry:
            bookingMemberFull?.bookingMember?.config?.inquiry,
        },
      );
      const bmConfigInquiry = bookingMemberFull.bookingMember.config?.inquiry;
      const formBookingMemberConfig: BookingMemberConfig = {
        inquiry: {
          // enabled: true, // TODO se baser sur la config globale
          inquiries: enabledInquiries.map((formInquiry) => {
            const defaultValueEnabled =
              activeInquiries.find(
                (memberActiveInquiry) =>
                  formInquiry._id === memberActiveInquiry._id,
              ) !== undefined;
            return {
              appInquiryId: formInquiry._id,
              enabled: defaultValueEnabled,
            };
          }),
        },
      };
      return {
        bookingMemberFull,
        config: formBookingMemberConfig,
      };
    });

    return bookingMembersConfigs;
  }, [
    bookingMembersFull,
    bookingParticipantsFull,
    clubResume.inquiries,
    enabledInquiries,
  ]);

  const initialFormValue = useMemo<BookingCustomerConfigEditFormModel>(() => {
    return buildFormValue({
      initialBooking,
      defaultBookingCustomerSpace,
      initialBookingMembersConfigs,
    });
  }, [
    defaultBookingCustomerSpace,
    initialBooking,
    initialBookingMembersConfigs,
  ]);

  const form = useForm<BookingCustomerConfigEditFormModel>({
    defaultValues: initialFormValue,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, watch, formState, control, setValue, reset } =
    form;

  const [generateIndividualLinks] = useWatch({
    control,
    name: ['booking.bookingCustomerSpacePrivate.generateIndividualLinks'],
  });
  const formValue = useWatch({
    control,
  });
  const hasFormChanges = useMemo(
    () => form && (formState.isDirty || formState.isValid),
    [form, formState.isDirty, formState.isValid],
  );

  const confirmChanges = useCallback(
    (
      onConfirm: (changes?: BookingCustomerConfigEditDialog_Changes) => void,
    ) => {
      return handleSubmit(
        (formValue: BookingCustomerConfigEditFormModel, event) => {
          if (hasFormChanges) {
            let bookingPatch: AppEntityUpdatePatch;
            const patchOperations = jsonPatcher.compareObjects(
              initialBooking,
              {
                ...initialBooking,
                ...formValue.booking,
              },
              {
                // else, value won't be deleted by typeorm
                // https://github.com/typeorm/typeorm/issues/2934
                replaceDeleteByNullValue: true,
              },
            );
            if (patchOperations.length) {
              bookingPatch = {
                pk: initialBooking._id,
                patchOperations,
              };
            }
            const bookingMembersPatches = formValue.bookingMembersConfigs
              .map((formBookingMemberConfig) => {
                const initialBookingMemberConfig =
                  initialBookingMembersConfigs.find(
                    (x) =>
                      x.bookingMemberFull.bookingMember?._id ===
                      formBookingMemberConfig.bookingMemberFull.bookingMember
                        ?._id,
                  );
                const initialBookingMember = {
                  ...initialBookingMemberConfig.bookingMemberFull.bookingMember,
                  config: {
                    ...initialBookingMemberConfig.config,
                  },
                };
                const patchOperations = jsonPatcher.compareObjects(
                  initialBookingMember,
                  {
                    ...initialBookingMember,
                    config: formBookingMemberConfig.config,
                  },
                  {
                    // else, value won't be deleted by typeorm
                    // https://github.com/typeorm/typeorm/issues/2934
                    replaceDeleteByNullValue: true,
                    attributesToReplaceFully: ['config'],
                  },
                );
                if (patchOperations.length) {
                  let bookingMemberPatch: AppEntityUpdatePatch = {
                    pk: formBookingMemberConfig.bookingMemberFull.bookingMember
                      ._id,
                    patchOperations,
                  };
                  return bookingMemberPatch;
                }
              })
              .filter((x) => !!x);
            if (bookingPatch || bookingMembersPatches.length > 0) {
              onConfirm({ bookingPatch, bookingMembersPatches });
            }
          } else {
            // no changes
            onConfirm(undefined);
          }
        },
        (err) => {
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.. Si cela persiste, merci de nous contacter.',
          });
          throw err;
        },
      )();
    },
    [
      handleSubmit,
      hasFormChanges,
      initialBooking,
      initialBookingMembersConfigs,
    ],
  );

  const submitForm = useCallback(async () => {
    try {
      await confirmChanges(
        (changes: BookingCustomerConfigEditDialog_Changes) => {
          if (changes) {
            submitChanges(changes, { persistNow: false });
          }
        },
      );
      setIsOpen(false);
    } catch (err) {
      // message already displayed
    }
  }, [confirmChanges, setIsOpen, submitChanges]);

  const resetWithLocalChanges = useCallback(
    (changes: BookingCustomerConfigEditDialog_Changes) => {
      if (changes) {
        let updatedInitialBooking = initialBooking;

        if (changes.bookingPatch) {
          updatedInitialBooking = jsonPatcherSmart.applySmartPatchOperations(
            initialBooking,
            {
              patchOperations: changes.bookingPatch.patchOperations,
              logPrefix: 'BookingCustomerConfigEditDialog',
              ignoreErrors: true,
              appLogger,
            },
          ).output;
          setInitialBooking(updatedInitialBooking);
        }
        const updatedBookingMembersConfigs = initialBookingMembersConfigs.map(
          (c) => {
            const patch = changes.bookingMembersPatches.find(
              (x) => x.pk === c.bookingMemberFull.bookingMember._id,
            );
            if (patch) {
              return {
                ...c,
                config: jsonPatcherSmart.applySmartPatchOperations(c.config, {
                  patchOperations: patch.patchOperations,
                  logPrefix: 'BookingCustomerConfigEditDialog',
                  ignoreErrors: true,
                  appLogger,
                }).output,
              };
            }
            return c; // pas de changement
          },
        );

        const formValue = buildFormValue({
          initialBooking: updatedInitialBooking,
          initialBookingMembersConfigs: updatedBookingMembersConfigs,
          defaultBookingCustomerSpace,
        });
        reset(formValue, {});
      }
    },
    [
      defaultBookingCustomerSpace,
      initialBooking,
      initialBookingMembersConfigs,
      reset,
    ],
  );

  const [
    customerUiEnableCustomerUpdate,
    customerUiPendingListDiversContactInfo,
    customerUiPendingListDiversDivingInfo,
    customerUiPendingListDiversDiversPostalAddressInfo,
    customerUiPendingListDiversExpectedDiveInfo,
  ] = useWatch({
    control,
    name: [
      'booking.bookingCustomerConfig.customerUi.enableCustomerUpdate',
      'booking.bookingCustomerConfig.pendingList.diversContactInfo',
      'booking.bookingCustomerConfig.pendingList.diversDivingInfo',
      'booking.bookingCustomerConfig.pendingList.diversPostalAddressInfo',
      'booking.bookingCustomerConfig.pendingList.diversExpectedDiveInfo',
    ],
  });

  const customerUpdateRequested =
    customerUiEnableCustomerUpdate &&
    (customerUiPendingListDiversContactInfo ||
      customerUiPendingListDiversDivingInfo ||
      customerUiPendingListDiversDiversPostalAddressInfo ||
      customerUiPendingListDiversExpectedDiveInfo);

  const [linkGenerationInProgress, setLinkGenerationInProgress] =
    useState(false);

  const diverLinks: CustomerSpaceDiverLink[] = useMemo(() => {
    const diverLinks = inputState.bookingMembersFull
      .map(({ booking, bookingMember, diver }) => {
        if (bookingMember.bookingAliasKey) {
          const url = appUrlBuilderCore.buildShortUrlLinkOnDiverSite({
            aliasKey: bookingMember.bookingAliasKey,
            diverWebUrl: appWebConfig.applications.diverWebUrl,
          });
          const customerSpaceDiverLink: CustomerSpaceDiverLink = {
            isBookingContact: booking.bookingContactDiverId === diver._id,
            bookingId,
            diverId: diver._id,
            firstName: diver.firstName,
            lastName: diver.lastName,
            emailAddress: diver.emailAddress,
            phoneNumber: diver.phoneNumber,
            url,
          };
          return customerSpaceDiverLink;
        }
      })
      .filter((x) => !!x);
    return diverLinks;
  }, [bookingId, inputState.bookingMembersFull]);

  const persistChanges = useCallback(async () => {
    try {
      if (inputState?.isNewBooking) {
        await confirmChanges(
          (changes: BookingCustomerConfigEditDialog_Changes) => {
            if (changes) {
              submitChanges(changes, { persistNow: true });
              resetWithLocalChanges(changes);
            }
          },
        );
      } else {
        // only persist local changes
        await confirmChanges(
          async (changes?: BookingCustomerConfigEditDialog_Changes) => {
            if (changes) {
              const payload: ProMultiOperationPayload = {
                logContext: 'edit customer config',
              };
              if (changes.bookingPatch) {
                payload.updatedBookings = [changes.bookingPatch];
              }
              if (changes.bookingMembersPatches?.length) {
                payload.updatedBookingMembers = changes.bookingMembersPatches;
              }
              await proMultiMutationsApiClient.update(payload);
              resetWithLocalChanges(changes);
            }
          },
        );
      }
    } catch (err) {}
  }, [
    confirmChanges,
    inputState?.isNewBooking,
    resetWithLocalChanges,
    submitChanges,
  ]);

  // const generateBookingLink = useCallback(
  //   async ({
  //     generateIndividualLinks,
  //   }: {
  //     generateIndividualLinks: boolean;
  //   }) => {
  //     if (!linkGenerationInProgress) {
  //       setLinkGenerationInProgress(true);
  //       try {
  //         await persistChanges();
  //         const diverLinks: CustomerSpaceDiverLink[] =
  //           await authenticationClient.createCustomerSpaceDiverLinks({
  //             bookingId,
  //             contactMemberOnly: !generateIndividualLinks,
  //           });
  //         setDiverLinks(diverLinks);
  //         return diverLinks;
  //       } finally {
  //         setLinkGenerationInProgress(false);
  //       }
  //     }
  //   },
  //   [bookingId, linkGenerationInProgress, persistChanges],
  // );

  const actionsPersist: UseClubDialogsProps = useClubDialogsActionsPersist({
    createMessageToCustomers: {
      onUpdate: () => {
        console.log('[useClubDialogsActionsPersist] onUpdate called!');
      },
    },
  });
  const dialogsState: ClubDialogsState = useClubDialogs(actionsPersist);

  const openCustomerSpaceConfigDialog = useCallback(() => {
    dialogsState.customerSpaceConfigDialog.openDialog({
      defaultValue: {
        clubSettings: clubResume?.clubSettings,
      },
    });
  }, [clubResume?.clubSettings, dialogsState.customerSpaceConfigDialog]);

  const tabs = useMemo(() => {
    const tabs: AppTabTailwind<BookingCustomerConfigTabId>[] = [];
    if (diverLinks?.length > 0) {
      tabs.push({
        id: 'tab-config',
        label: 'Configuration',
      });
      tabs.push({
        id: 'tab-links',
        label: 'Lien de connexion',
      });
      // tabs.push({
      //   id: 'tab-messages',
      //   label: 'Envoyer un message',
      // });
    }
    return tabs;
  }, [diverLinks?.length]);

  return {
    data: {
      clubReference,
      enabledInquiries,
      initialBooking,
      bookingMembersFull,
      bookingParticipantsFull,
      diverLinks,
    },
    state: {
      form,
      hasFormChanges,
      dialogsState,
      customerUpdateRequested,
      linkGenerationInProgress,
      tabs,
      tab,
      setTab,
    },
    actions: {
      setIsOpen,
      submitForm,
      confirmChanges,
      openCustomerSpaceConfigDialog,
      resetWithLocalChanges,
      persistChanges,
      generateIndividualLinks,
    },
  };
}

export type BookingCustomerConfigEditDialogLocalState = ReturnType<
  typeof useBookingCustomerConfigEditDialogLocalState
>;
function buildFormValue({
  initialBooking,
  defaultBookingCustomerSpace,
  initialBookingMembersConfigs,
}: {
  initialBooking: Pick<
    Booking,
    | '_id'
    | 'bookingCustomerConfig'
    | 'bookingCustomerSpacePrivate'
    | 'bookingContactDiverId'
  >;
  defaultBookingCustomerSpace: {
    bookingCustomerConfig: BookingCustomerConfig;
    bookingCustomerSpacePrivate: BookingCustomerSpacePrivate;
  };
  initialBookingMembersConfigs: {
    bookingMemberFull: PRO_BookingMemberFull;
    config: BookingMemberConfig;
  }[];
}) {
  const formValue: BookingCustomerConfigEditFormModel = {
    booking: {
      bookingCustomerConfig:
        initialBooking.bookingCustomerConfig ??
        defaultBookingCustomerSpace.bookingCustomerConfig,
      bookingCustomerSpacePrivate:
        initialBooking.bookingCustomerSpacePrivate ??
        defaultBookingCustomerSpace.bookingCustomerSpacePrivate,
    },
    bookingMembersConfigs: initialBookingMembersConfigs,
  };
  return formValue;
}
