/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import {
  AppFixedButtonsBar,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import {
  AppButtonDeprecated,
  AppTabsTailwind,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { ClubDialogsProvider } from 'src/pages/_dialogs';
import { BookingCustomerConfigEditDialogPanel1Config } from './BookingCustomerConfigEditDialogPanel1Config';
import { BookingCustomerConfigEditDialogPanel2Links } from './BookingCustomerConfigEditDialogPanel2Links';
import { BookingCustomerConfigEditDialogInputState } from './_model';
import {
  BookingCustomerConfigEditDialog_Changes,
  BookingCustomerConfigEditDialogLocalState,
  useBookingCustomerConfigEditDialogLocalState,
} from './useBookingCustomerConfigEditDialogLocalState.hook';

export const BookingCustomerConfigEditDialog: FC<{
  state: BookingCustomerConfigEditDialogInputState;
  setState: React.Dispatch<
    React.SetStateAction<BookingCustomerConfigEditDialogInputState>
  >;
  submitChanges?: (
    changes: BookingCustomerConfigEditDialog_Changes,
    { persistNow }: { persistNow: boolean },
  ) => Promise<void>;
}> = ({ submitChanges, state: inputState, setState: setInputState }) => {
  const localState: BookingCustomerConfigEditDialogLocalState =
    useBookingCustomerConfigEditDialogLocalState({
      inputState,
      setInputState,
      submitChanges,
    });

  const { state, actions, data } = localState;

  return !inputState.isOpen ? null : (
    <ClubDialogsProvider dialogsState={state.dialogsState}>
      <AppPageContainerWithFixedBars
        className="bg-gray-50"
        contentClassName="bg-white app-p-content"
        paddingBottom={true}
        marginBottom={false}
        footerBar={() => (
          <AppFixedButtonsBar hasChanges={true}>
            <>
              <AppButtonDeprecated
                icon={AppHeroIcons.actionCancel}
                buttonStyle="outline-transparent"
                size="normal"
                onClick={() => {
                  actions.setIsOpen(false);
                }}
              >
                Annuler
              </AppButtonDeprecated>
              <AppButtonDeprecated
                icon={AppHeroIcons.actionSave}
                buttonStyle="outline-transparent-primary"
                onClick={() => {
                  actions.submitForm();
                }}
              >
                Confirmer
              </AppButtonDeprecated>
            </>
          </AppFixedButtonsBar>
        )}
      >
        <h3 className="mt-2 text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
          Espace client
        </h3>
        <AppTabsTailwind
          className="my-2 w-full"
          tabs={state.tabs}
          selected={state.tab}
          theme="underline"
          breakpoint="sm"
          onChange={(tabId) => state.setTab(tabId)}
        />
        {state.tab === 'tab-config' && (
          <BookingCustomerConfigEditDialogPanel1Config
            className="mt-5 mx-1 relative"
            localState={localState}
            inputState={inputState}
          />
        )}
        {state.tab === 'tab-links' && (
          <BookingCustomerConfigEditDialogPanel2Links
            className="mt-5 mx-1 relative"
            localState={localState}
            inputState={inputState}
          />
        )}
        {/* {tab === 'tab-messages' && (
          <BookingCustomerConfigEditDialogPanel3Messages
            className="mt-5 mx-1 relative"
            localState={localState}
            inputState={inputState}
          />
        )} */}
      </AppPageContainerWithFixedBars>
    </ClubDialogsProvider>
  );
};
