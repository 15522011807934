import { BookingSessionParticipant } from '@mabadive/app-common-model';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BookingParticipantEditorParticipant } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import {
  LoadableContent,
  LoadableContentPartial,
  useLoadableContent,
} from '../../../app-loading';
import { bookingParticipantEditorFetcher } from '../graphql';
import {
  BookingParticipantEditorFetcherCriteria,
  BookingParticipantEditorGraphqlQueryReturnType,
} from '../model';

export function useLoadableBookingParticipantEditor({
  criteria,
}: {
  criteria: BookingParticipantEditorFetcherCriteria;
}): LoadableContent<BookingParticipantEditorParticipant> {
  return useLoadableContent(
    () => {
      const loadable$ = !criteria?.clubParticipantId
        ? of({
            content: undefined,
            contentState: 'none',
            lastActionStatus: 'success',
          } as LoadableContentPartial<BookingParticipantEditorParticipant>)
        : bookingParticipantEditorFetcher
            .findOne(criteria, { type: 'query' })
            .pipe(
              map((data: BookingParticipantEditorGraphqlQueryReturnType) => {
                const {
                  bookingId,
                  bookingSessionId,
                  diveSession,
                  diver,
                  bookingMember,
                  bookingProducts,
                  diveSessionParticipant,
                } = data;
                const bookingSessionParticipant: BookingSessionParticipant = {
                  ...data,
                  bookingId: undefined,
                  bookingSessionId: undefined,
                  diveSession: undefined,
                  diver: undefined,
                  bookingMember: undefined,
                  bookingProduct: undefined,
                  clubParticipant: undefined,
                } as unknown as BookingSessionParticipant;
                const content: BookingParticipantEditorParticipant = {
                  bookingSessionParticipant,
                  bookingId,
                  bookingSessionId,
                  diveSession,
                  diver,
                  bookingMember,
                  bookingProduct: (bookingProducts ?? []).find(
                    (x) => x.type === 'dive',
                  ),
                  clubParticipant: diveSessionParticipant,
                  docResumes: [],
                  inquiryResponses: [],
                };
                const loadableContent: LoadableContentPartial<BookingParticipantEditorParticipant> =
                  {
                    contentState: 'full',
                    lastActionStatus: 'success',
                    content,
                  };
                return loadableContent;
              }),
            );
      return loadable$;
    },
    [criteria],
    {
      debugName: 'useLoadableBookingEditionBookingResumes',
      useSnapshot: false,
    },
  );
}
