/* eslint-disable @typescript-eslint/no-unused-vars */
import { PRO_AppDocResume } from '@mabadive/app-common-model';
import {
  appDocRefFormatter,
  appDocStatusFormatter,
  dateService,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppDocFileTypeIcon } from 'src/business/_core/modules/layout';
import { CommentLabel } from '../../_common/form';

export const AppDocViewPanelDoc = ({
  doc,
  onClick,
}: {
  doc: PRO_AppDocResume;
  onClick: () => void;
}) => {
  const docRefLabel = appDocRefFormatter.formatAppDocRef(doc.docRef);
  const appDocStatusLabel = appDocStatusFormatter.formatAppDocStatus(
    doc.status,
  );

  // {doc.details.docValidityInYears}
  const exipirationDate = useMemo(() => {
    const docDate = doc.details?.docDate;
    const docValidityInYears = doc.details?.docValidityInYears;
    if (docValidityInYears) {
      const exipirationDate = new Date(docDate);
      exipirationDate.setFullYear(
        exipirationDate.getFullYear() + docValidityInYears,
      );
      return exipirationDate;
    }
  }, [doc.details?.docDate, doc.details?.docValidityInYears]);

  const isExpired = useMemo(() => {
    return exipirationDate < new Date();
  }, [exipirationDate]);

  return (
    <div
      className={clsx(
        'app-card py-2 px-4 grid gap-y-4',
        onClick && 'cursor-pointer',
      )}
      onClick={onClick}
    >
      <div className="overflow-hidden flex flex-col items-stretch gap-x-4 gap-y-2">
        <div className="truncate flex gap-2 items-center text-app-primary">
          <AppDocFileTypeIcon
            fileType={doc.fileType}
            className="flex-shrink-0 w-6 h-6 md:w-8 md:h-8"
          />
          <div className="flex-shrink">{doc.label ?? docRefLabel}</div>
        </div>
        {doc.details?.comment && (
          <CommentLabel
            className="text-xs text-gray-600"
            comment={doc.details?.comment}
          />
        )}
        <div className="flex-shrink truncate flex gap-4 justify-between items-center text-xs">
          <div
            className={clsx(
              'flex-shrink uppercase',
              isExpired || doc.status === 'rejected'
                ? 'text-status-error'
                : doc.status === 'validated'
                ? 'text-status-success'
                : doc.status === 'submitted'
                ? 'text-status-warn'
                : 'text-gray-600',
            )}
          >
            {isExpired ? 'Expiré' : appDocStatusLabel}
          </div>

          <div className="text-gray-600">
            {doc.details?.docDate &&
              dateService.formatLocal(doc.details?.docDate, 'DD/MM/YYYY HH:mm')}
          </div>
        </div>
      </div>
    </div>
  );
};
