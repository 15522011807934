import { BookingProduct } from '@mabadive/app-common-model';
import { appLogger } from 'src/business/_core/modules/root/logger';
import {
  DiverBookingPageAggregatedDataCore,
  PRO_BookingParticipantFull,
} from '../../models';
import { DiverBookingPageAdditionalDataToLoad } from '../01.loaded-content';

export const diverBookingPageAggregatedBookingResumeBuilderParticipantFull = {
  buildParticipantFull,
};

function buildParticipantFull({
  aggregatedDataCore,
  bookingProduct,
  missingData,
}: {
  aggregatedDataCore: DiverBookingPageAggregatedDataCore;
  bookingProduct: BookingProduct;
  missingData?: DiverBookingPageAdditionalDataToLoad;
}): PRO_BookingParticipantFull {
  const booking = aggregatedDataCore.bookings.find(
    (x) => x._id === bookingProduct.bookingId,
  );
  if (!booking) {
    // chargement des données incohérent
    // eslint-disable-next-line no-console
    appLogger.warn(
      `Booking "${bookingProduct.bookingId}" not found from product "${bookingProduct._id}"`,
    );
    if (
      missingData &&
      !missingData.bookingIds.includes(bookingProduct.bookingId)
    ) {
      // trigger booking be loaded
      missingData.bookingIds.push(bookingProduct.bookingId);
    }
    return null; // report bug but ignore participant to avoid page crash
  }
  const bookingMember = aggregatedDataCore.bookingMembers.find(
    (x) => x._id === bookingProduct.bookingMemberId,
  );
  const docResumes = aggregatedDataCore.docResumes.filter(
    (x) => x.clubDiverId === bookingMember.diverId,
  );
  const inquiryResponses = aggregatedDataCore.inquiryResponses.filter(
    (x) => x.diverId === bookingMember.diverId,
  );

  if (!bookingMember) {
    // chargement des données incohérent
    // eslint-disable-next-line no-console
    appLogger.warn(
      `Booking member "${bookingProduct.bookingMemberId}" not found from product "${bookingProduct._id}"`,
    );
    return null; // report bug but ignore participant to avoid page crash
  }
  const diver = aggregatedDataCore.divers.find(
    (x) => x._id === bookingMember?.diverId,
  );
  if (!diver) {
    // chargement des données incohérent
    // eslint-disable-next-line no-console
    appLogger.warn(
      `Diver "${bookingMember.diverId}" not found from member "${bookingMember._id}" and product "${bookingProduct._id}"`,
    );
    if (missingData && !missingData.diverIds.includes(bookingProduct.diverId)) {
      // trigger booking be loaded
      missingData.diverIds.push(bookingProduct.diverId);
    }
    return null; // report bug but ignore participant to avoid page crash
  }
  const bookingSession = aggregatedDataCore.bookingSessions.find(
    (x) => x._id === bookingProduct.bookingSessionId,
  );
  if (!bookingSession) {
    // chargement des données incohérent
    // eslint-disable-next-line no-console
    appLogger.warn(
      `Booking session "${bookingProduct.bookingSessionId}" not found product "${bookingProduct._id}"`,
    );
    return null; // report bug but ignore participant to avoid page crash
  }
  const diveSession = aggregatedDataCore.diveSessions.find(
    (x) => x.reference === bookingSession?.diveSessionReference,
  );
  if (!diveSession) {
    // chargement des données incohérent
    // eslint-disable-next-line no-console
    appLogger.warn(
      `Dive session "${bookingSession?.diveSessionReference}" not found from booking session "${bookingSession._id}" and product "${bookingProduct._id}"`,
    );
    return null; // report bug but ignore participant to avoid page crash
  }
  const bookingSessionParticipant =
    aggregatedDataCore.bookingSessionParticipants.find(
      (x) => x._id === bookingProduct.bookingSessionParticipantId,
    );
  if (!bookingSessionParticipant) {
    // chargement des données incohérent
    // eslint-disable-next-line no-console
    appLogger.warn(
      `Booking session participant "${bookingProduct.bookingSessionParticipantId}" not found product "${bookingProduct._id}"`,
    );
    return null; // report bug but ignore participant to avoid page crash
  }
  const diveSessionParticipant = aggregatedDataCore.clubParticipants.find(
    (x) => x._id === bookingSessionParticipant?.participantId,
  );
  if (!diveSessionParticipant) {
    // chargement des données incohérent
    // eslint-disable-next-line no-console
    appLogger.warn(
      `Dive session participant "${bookingSessionParticipant?.participantId}" not found from booking session participant "${bookingSessionParticipant._id}", booking session "${bookingSession._id}" and product "${bookingProduct._id}"`,
    );
    return null; // report bug but ignore participant to avoid page crash
  }

  const isCreatedParticipant =
    aggregatedDataCore.created.bookingSessionParticipantsIds.includes(
      bookingSessionParticipant._id,
    );

  const bookingParticipantFull: PRO_BookingParticipantFull = {
    diveSession,
    booking,
    bookingSession,
    bookingProductDive: bookingProduct,
    diver,
    diveSessionParticipant,
    bookingSessionParticipant,
    bookingMember,
    docResumes,
    inquiryResponses,
    entityState: isCreatedParticipant ? 'created' : 'original', // TODO prendre en compte si besoin l'état 'updated'
  };
  return bookingParticipantFull;
}
