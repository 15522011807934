import {
  AppInquiryResponseForBookingGql_Company,
  Booking,
  BookingCustomerUpdate,
  BookingDeposit,
  BookingMember,
  BookingProduct,
  BookingSession,
  BookingSessionParticipant,
  ClubDiver,
  ClubPurchasePackage,
  ClubPurchasePayment,
  ClubPurchasePaymentBookingDepositDetails,
  ClubPurchasePaymentPackageDetails,
  CreditNote,
  DiveSession,
  OnlineBooking,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import { changeDescriptorAggregator } from '@mabadive/app-common-services';
import { appLogger } from 'src/business/_core/modules/root/logger';
import {
  DiverBookingPageAggregatedDataCore,
  DiverBookingPageLoadedContent,
  DiverBookingPageUpdateState,
} from '../../models';
export const diverBookingPageAggregatedBookingResumeBuilderCore = {
  aggregateDataCore,
};

function aggregateDataCore({
  updateState,
  loadedContent,
}: {
  updateState: DiverBookingPageUpdateState;
  loadedContent: Pick<
    DiverBookingPageLoadedContent,
    | 'linkedData'
    | 'bookingMembers'
    | 'bookings'
    | 'bookingSessions'
    | 'bookingSessionParticipants'
    | 'bookingProducts'
    | 'clubParticipants'
    | 'clubDivers'
    | 'diveSessions'
    | 'purchasePackages'
    | 'purchasePayments'
    | 'bookingCustomerUpdates'
    | 'bookingDeposits'
    | 'creditNotes'
    | 'paymentsPackagesDetails'
    | 'paymentsBookingDepositsDetails'
    | 'docResumes'
    | 'inquiryResponses'
    | 'onlineBookings'
  >;
}): DiverBookingPageAggregatedDataCore {
  const onlineBookings: OnlineBooking[] =
    changeDescriptorAggregator.aggregateMany(
      updateState.onlineBookingsChanges,
      {
        pk: '_id',
        initials: loadedContent.onlineBookings,
        ignoreErrors: true,
        appLogger,
        logPrefix: 'onlineBookings',
      },
    );
  const bookings: Booking[] = changeDescriptorAggregator.aggregateMany(
    updateState.bookingsChanges,
    {
      pk: '_id',
      initials: loadedContent.bookings,
      ignoreErrors: true,
      appLogger,
      logPrefix: 'bookings',
    },
  );
  const bookingSessions: BookingSession[] =
    changeDescriptorAggregator.aggregateMany(
      updateState.bookingSessionsChanges,

      {
        pk: '_id',
        initials: loadedContent.bookingSessions,
        ignoreErrors: true,
        appLogger,
        logPrefix: 'bookingSessions',
      },
    );
  const bookingMembers: BookingMember[] =
    changeDescriptorAggregator.aggregateMany(
      updateState.bookingMembersChanges,
      {
        pk: '_id',
        initials: loadedContent.bookingMembers,
        ignoreErrors: true,
        appLogger,
        logPrefix: 'bookingMembers',
      },
    );

  const bookingSessionParticipants: BookingSessionParticipant[] =
    changeDescriptorAggregator.aggregateMany(
      updateState.bookingSessionParticipantsChanges,

      {
        pk: '_id',
        initials: loadedContent.bookingSessionParticipants,
        ignoreErrors: true,
        appLogger,
        logPrefix: 'bookingSessionParticipants',
      },
    );

  const bookingCustomerUpdates: BookingCustomerUpdate[] =
    changeDescriptorAggregator.aggregateMany(
      updateState.bookingCustomerUpdatesChanges,

      {
        pk: '_id',
        initials: loadedContent.bookingCustomerUpdates,
        ignoreErrors: true,
        appLogger,
        logPrefix: 'bookingCustomerUpdates',
      },
    );
  const bookingProducts: BookingProduct[] =
    changeDescriptorAggregator.aggregateMany(
      updateState.bookingProductsChanges,
      {
        pk: '_id',
        initials: loadedContent.bookingProducts,
        ignoreErrors: true,
        appLogger,
        logPrefix: 'bookingProducts',
      },
    );
  const clubParticipants = changeDescriptorAggregator.aggregateMany(
    updateState.clubParticipantsChanges,
    {
      pk: '_id',
      initials: loadedContent.clubParticipants,
      ignoreErrors: true,
      appLogger,
      logPrefix: 'clubParticipants',
    },
  );

  const divers: ClubDiver[] = changeDescriptorAggregator.aggregateMany(
    updateState.diversChanges,

    {
      pk: '_id',
      initials: loadedContent.clubDivers,
      ignoreErrors: true,
      appLogger,
      logPrefix: 'clubDivers',
    },
  );

  const diversLoaded = divers.filter((x) =>
    loadedContent?.linkedData?.diverIdsBookingLoaded.includes(x._id),
  );
  const diveSessions: DiveSession[] = changeDescriptorAggregator.aggregateMany(
    updateState.diveSessionsChanges,

    {
      pk: '_id',
      initials: loadedContent.diveSessions,
      ignoreErrors: true,
      appLogger,
      logPrefix: 'diveSessions',
    },
  );

  const purchasePackages: ClubPurchasePackage[] =
    changeDescriptorAggregator.aggregateMany(
      updateState.purchasePackagesChanges,

      {
        pk: '_id',
        initials: loadedContent.purchasePackages,
        ignoreErrors: true,
        appLogger,
        logPrefix: 'purchasePackages',
      },
    );

  const purchasePayments: ClubPurchasePayment[] =
    changeDescriptorAggregator.aggregateMany(
      updateState.purchasePaymentsChanges,

      {
        pk: '_id',
        initials: loadedContent.purchasePayments,
        ignoreErrors: true,
        appLogger,
        logPrefix: 'purchasePayments',
      },
    );

  const paymentsPackagesDetails: ClubPurchasePaymentPackageDetails[] =
    changeDescriptorAggregator.aggregateMany(
      updateState.paymentsPackagesDetailsChanges,

      {
        pk: '_id',
        initials: loadedContent.paymentsPackagesDetails,
        ignoreErrors: true,
        appLogger,
        logPrefix: 'paymentsPackagesDetails',
      },
    );

  const paymentsBookingDepositsDetails: ClubPurchasePaymentBookingDepositDetails[] =
    changeDescriptorAggregator.aggregateMany(
      updateState.paymentsBookingDepositsDetailsChanges,

      {
        pk: '_id',
        initials: loadedContent.paymentsBookingDepositsDetails,
        ignoreErrors: true,
        appLogger,
        logPrefix: 'paymentsBookingDepositsDetails',
      },
    );

  const bookingDeposits: BookingDeposit[] =
    changeDescriptorAggregator.aggregateMany(
      updateState.bookingDepositsChanges,
      {
        pk: '_id',
        initials: loadedContent.bookingDeposits,
        ignoreErrors: true,
        appLogger,
        logPrefix: 'bookingDeposits',
      },
    );
  const creditNotes: CreditNote[] = changeDescriptorAggregator.aggregateMany(
    updateState.creditNotesChanges,
    {
      pk: '_id',
      initials: loadedContent.creditNotes,
      ignoreErrors: true,
      appLogger,
      logPrefix: 'creditNotes',
    },
  );
  const docResumes: PRO_AppDocResume[] =
    changeDescriptorAggregator.aggregateMany(updateState.docResumesChanges, {
      pk: '_id',
      initials: loadedContent.docResumes,
      ignoreErrors: true,
      appLogger,
      logPrefix: 'docResumes',
    });

  const inquiryResponses: AppInquiryResponseForBookingGql_Company[] =
    changeDescriptorAggregator.aggregateMany(
      updateState.inquiryResponseChanges,
      {
        pk: '_id',
        initials: loadedContent.inquiryResponses,
        ignoreErrors: true,
        appLogger,
        logPrefix: 'inquiryResponses',
      },
    );

  const createdBookingIds = (updateState.bookingsChanges ?? [])
    .filter((x) => x.created)
    .map((x) => x.pk);

  const createdBookingSessionsIds = (updateState.bookingSessionsChanges ?? [])
    .filter((x) => x.created)
    .map((x) => x.pk);

  const createdBookingSessionParticipantsIds = (
    updateState.bookingSessionParticipantsChanges ?? []
  )
    .filter((x) => x.created)
    .map((x) => x.pk);

  const aggregatedDataCore: DiverBookingPageAggregatedDataCore = {
    isFullyLoaded: true,
    linkedData: loadedContent.linkedData,
    bookings,
    onlineBookings,
    bookingSessions,
    bookingMembers,
    bookingSessionParticipants,
    bookingProducts,
    bookingCustomerUpdates,
    paymentsPackagesDetails,
    paymentsBookingDepositsDetails,
    clubParticipants,
    divers,
    diversLoaded,
    diveSessions,
    purchasePackages,
    purchasePayments,
    bookingDeposits,
    creditNotes,
    created: {
      bookingIds: createdBookingIds,
      bookingSessionsIds: createdBookingSessionsIds,
      bookingSessionParticipantsIds: createdBookingSessionParticipantsIds,
    },
    docResumes,
    inquiryResponses,
  };
  return aggregatedDataCore;
}
