/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { AppDropzone } from 'src/business/_core/modules/layout';
import { AppDocumentCreatorFormAppDoc } from '../_model';
import { AppDocEditDialogLocalState } from '../useAppDocEditDialogLocalState';
import { AppDocumentCreatorPanelFormItem } from './AppDocumentCreatorPanelFormItem';

export const AppDocumentCreatorPanelForm = ({
  localState,
}: {
  localState: AppDocEditDialogLocalState;
}) => {
  const form = localState.state.form;
  const formItems = useFieldArray({
    control: form.control,
    name: 'items',
    rules: {
      validate:
        localState.state.mode === 'create'
          ? (items) => {
              // valide seulement si tous les documents ont été uploadés
              const isValidArray =
                items.find((x) => x.uploadStatus !== 'success') === undefined;

              if (isValidArray) {
                return null;
              } else {
                return 'Tous les documents doivent être uploadés';
              }
            }
          : undefined,
    },
  });

  return (
    <div className="grid gap-4">
      {localState.state.mode === 'create' && (
        <AppDropzone
          className=""
          maxFileSizeLabel="10 Mo"
          acceptFileTypes={['richText', 'image', 'spreadSheet', 'archive']}
          label="Ajouter un document ou justificatif"
          onFileLoaded={({ file, content, fileType }) => {
            const appDoc: AppDocumentCreatorFormAppDoc = {
              label: '',
              fileType,
              docRef: null,
              details: {
                docDate: new Date(),
              },
              status: 'validated',
            };
            formItems.append({
              appDoc,
              file: file,
              uploadStatus: 'pending',
            });
          }}
        />
      )}
      {formItems.fields.length > 0 && (
        <div className="grid gap-2">
          {formItems.fields.map((item, itemIndex) => (
            <AppDocumentCreatorPanelFormItem
              key={itemIndex}
              localState={localState}
              item={item}
              itemIndex={itemIndex}
            />
          ))}
        </div>
      )}
    </div>
  );
};
