/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdateDescriptor,
  BookingParticipantEditorFormModel,
  ClubPlanningLightSessionDef,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { clubMassiveUpdatorClient } from 'src/business/_core/data/app-operation';
import {
  BookingParticipantEditorFetcherCriteria,
  useLoadableBookingParticipantEditor,
} from 'src/business/_core/data/store-repository';
import { uiStore } from 'src/business/_core/store';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { ClubDialogsState } from 'src/pages/_dialogs';
import {
  BookingParticipantEditorParticipant,
  BookingParticipantEditorResultChanges,
  BookingParticipantViewDialogInputState,
  BookingParticipantViewParticipant,
  ParticipantFormExpansionPanelId,
} from '../../../models';
import { participantEditorFormInitialValueBuilder } from '../BookingParticipantEditorDialog/services';
import { bookingParticipantEditorDialogChangesBuilderEditParticipant } from '../BookingParticipantEditorDialog/services/changes-builder';
import {
  BookingParticipantViewAggregatedData,
  BookingParticipantViewState,
} from './model';
import { sessionMassiveActionClient } from './services/sessionMassiveActionClient.service';

export function useBookingParticipantViewDialogLocalState({
  dialogsState,
  inputState,
  setInputState,
}: {
  dialogsState: ClubDialogsState;
  inputState: BookingParticipantViewDialogInputState;
  setInputState: React.Dispatch<
    React.SetStateAction<BookingParticipantViewDialogInputState>
  >;
}) {
  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      setInputState({
        ...inputState,
        isOpen,
      });
    },
    [setInputState, inputState],
  );

  const [viewState, setViewState] = useState<BookingParticipantViewState>({
    mode: 'view-participant',
  });

  const diveCenterResume = useDiveCenterResume();
  const clubSettings = diveCenterResume?.clubResume?.clubSettings;
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;

  const [isPersistInProgress, setIsPersistInProgress] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const participantDataInitialPartial: BookingParticipantViewParticipant =
    inputState.participant;

  const form = useForm<BookingParticipantEditorFormModel>({
    defaultValues: {}, // will be updated once data is fetched
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const defaultExpandedPanel: ParticipantFormExpansionPanelId = undefined;

  const [expandedPanel, setExpandedPanel] =
    useState<ParticipantFormExpansionPanelId>(defaultExpandedPanel);

  const expandPanelToogle = useCallback(
    (panelId: ParticipantFormExpansionPanelId) => {
      setExpandedPanel(expandedPanel === panelId ? undefined : panelId);
    },
    [expandedPanel],
  );

  const formValue = useWatch({
    control,
  }) as BookingParticipantEditorFormModel;

  const [participantDataInitialFull, setParticipantDataInitialFull] =
    useState<BookingParticipantEditorParticipant>();

  // pour l'instant, pas utilisé sur cet écran (mais mutualisé avec l'autre dialog d'édition de participant)
  const [docResumesChanges, setDocResumesChanges] = useState<
    AppEntityUpdateDescriptor<PRO_AppDocResume>[]
  >([]);

  const loadableBookingParticipantEditorCriteria: BookingParticipantEditorFetcherCriteria =
    useMemo(() => {
      return {
        clubParticipantId: participantDataInitialPartial?.clubParticipant?._id,
        clubReference,
      };
    }, [clubReference, participantDataInitialPartial?.clubParticipant?._id]);

  const { contentState, content, lastActionStatus } =
    useLoadableBookingParticipantEditor({
      criteria: loadableBookingParticipantEditorCriteria,
    });

  useEffect(() => {
    if (contentState === 'full') {
      const formValue =
        participantEditorFormInitialValueBuilder.buildInitialFormValue({
          mode: 'edit-participant',
          participantData: content,
          diveCenterResume,
        });
      form.reset(formValue, {});
      setParticipantDataInitialFull(content);
    }
  }, [content, contentState, form, diveCenterResume]);

  // loaded bookings + changes applied
  const {
    aggregatedData,
    changes,
  }: {
    aggregatedData: BookingParticipantViewAggregatedData;
    changes?: BookingParticipantEditorResultChanges;
  } = useMemo(() => {
    if (!participantDataInitialFull) {
      const i = participantDataInitialPartial;
      const aggregatedData: BookingParticipantViewAggregatedData = {
        state: 'partial',
        hasChanges: false,
        participantData: {
          bookingId: undefined,
          bookingMember: undefined,
          bookingSessionId: undefined,
          bookingProduct: undefined,
          bookingSessionParticipant: undefined,
          diver: i.diver,
          clubParticipant: i.clubParticipant,
          diveSession: i.diveSession,
          docResumes: [],
          inquiryResponses: [],
        },
      };
      return { aggregatedData };
    }

    const { hasChanges, result } =
      bookingParticipantEditorDialogChangesBuilderEditParticipant.buildChangesResult(
        {
          logContext: 'useBookingParticipantViewDialogLocalState',
          participantData: participantDataInitialFull,
          formValue,
          hasFormChanges: formState.isDirty,
          clubReference,
          initialFormValue:
            formState.defaultValues as BookingParticipantEditorFormModel,
        },
      );

    if (hasChanges) {
      const aggregatedData: BookingParticipantViewAggregatedData = {
        state: 'full',
        hasChanges: true,
        participantData: {
          ...participantDataInitialFull,
          bookingMember: result.aggregated.finalBookingMember,
          bookingProduct: result.aggregated.finalBookingProduct,
          bookingSessionParticipant:
            result.aggregated.finalBookingSessionParticipant,
          diver: result.aggregated.finalDiver,
          clubParticipant: result.aggregated.finalClubParticipant,
        },
      };
      return { aggregatedData, changes: result.changes };
    } else {
      const aggregatedData: BookingParticipantViewAggregatedData = {
        state: 'full',
        hasChanges: false,
        participantData: participantDataInitialFull,
      };
      return { aggregatedData };
    }
  }, [
    participantDataInitialFull,
    formValue,
    formState.isDirty,
    formState.defaultValues,
    clubReference,
    participantDataInitialPartial,
  ]);

  const mode: 'view' | 'loading' | 'edit' = useMemo(() => {
    if (expandedPanel) {
      return aggregatedData.state === 'full' ? 'edit' : 'loading';
    }
    return 'view';
  }, [aggregatedData.state, expandedPanel]);

  const { participantData } = aggregatedData;

  const onConfirmDeleteParticipant = useCallback(async () => {
    if (participantData?.bookingProduct) {
      await clubMassiveUpdatorClient.update({
        bookingIdsToClean: [aggregatedData.participantData.bookingId].filter(
          (x) => !!x,
        ),
        deletedBookingProductsIds: [participantData?.bookingProduct?._id],
        deletedBookingSessionParticipantsIds: [
          participantData?.bookingSessionParticipant?._id,
        ],
        deletedClubParticipantsIds: [participantData?.clubParticipant?._id],
      });
    }
  }, [
    aggregatedData.participantData.bookingId,
    participantData?.bookingProduct,
    participantData?.bookingSessionParticipant?._id,
    participantData?.clubParticipant?._id,
  ]);

  const onConfirmMoveParticipant = useCallback(
    async (selectedSession: ClubPlanningLightSessionDef) => {
      setIsPersistInProgress(true);
      await sessionMassiveActionClient
        .moveParticipant({
          sourceParticipantId: participantData?.clubParticipant?._id,
          sourceSession: participantData.diveSession,
          targetSession: selectedSession,
          bookingId: aggregatedData.participantData.bookingId,
          clubReference,
          diveCenterId,
        })
        .then(() => {
          setInputState({
            isOpen: false,
          });
        })
        .catch((err) => {
          setIsPersistInProgress(false);

          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Erreur innatendue. Veuillez vérifier votre connexion Internet et ré-essayer. Si cela persiste, merci de nous contacter.',
          });
        });
    },
    [
      aggregatedData.participantData.bookingId,
      clubReference,
      diveCenterId,
      participantData?.clubParticipant?._id,
      participantData.diveSession,
      setInputState,
    ],
  );

  const onClickMove = useCallback(() => {
    setViewState({
      mode: 'move-participant-select-dialog',
    });
  }, []);

  return {
    viewState,
    setViewState,
    form,
    aggregatedData,
    changes,
    clubReference,
    isPersistInProgress,
    setIsPersistInProgress,
    expandedPanel,
    setExpandedPanel,
    expandPanelToogle,
    isConfirmDialogOpen,
    setIsConfirmDialogOpen,
    setIsOpen,
    mode,
    inputState,
    onClickMove,
    onConfirmDeleteParticipant,
    onConfirmMoveParticipant,
    dialogsState,
  };
}

export type BookingParticipantViewDialogLocalState = ReturnType<
  typeof useBookingParticipantViewDialogLocalState
>;
