/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppDoc,
  AppEntityUpdateDescriptor,
  AppEntityUpdatePatch,
  BookingParticipantEditorFormModel,
  PRO_AppDocResume,
} from '@mabadive/app-common-model';
import { changeDescriptorAggregator } from '@mabadive/app-common-services';
import { UseFormReturn } from 'react-hook-form';
import { appLogger } from 'src/business/_core/modules/root/logger';
import {
  BookingParticipantEditorDialogState,
  BookingParticipantEditorParticipant,
  BookingParticipantEditorResult,
  BookingParticipantEditorResultChanges,
} from '../../../../../models';
import { bookingParticipantEditorDialogChangesBuilderCreateParticipant } from './bookingParticipantEditorDialogChangesBuilderCreateParticipant';
import { bookingParticipantEditorDialogChangesBuilderEditDiver } from './bookingParticipantEditorDialogChangesBuilderEditDiver';
import { bookingParticipantEditorDialogChangesBuilderEditParticipant } from './bookingParticipantEditorDialogChangesBuilderEditParticipant';

export const bookingParticipantEditDialogAggregator = { aggregate };

function aggregate(params: {
  state: BookingParticipantEditorDialogState;
  formValue: BookingParticipantEditorFormModel;
  clubReference: string;
  diveCenterId: string;
  initialFormValue: BookingParticipantEditorFormModel;
  participantDataInitialFull: BookingParticipantEditorParticipant;
  form: UseFormReturn<Partial<BookingParticipantEditorFormModel>>;
  docResumesChanges: AppEntityUpdateDescriptor<PRO_AppDocResume>[];
}): {
  aggregatedParticipantData: BookingParticipantEditorParticipant;
  changes?: BookingParticipantEditorResultChanges;
} {
  const { aggregatedParticipantData, changes: changesInput } =
    aggregateCore(params);

  const { appDocs, docResumes } = aggregateDocs(params);
  let changes = changesInput;
  if (
    appDocs?.created?.length > 0 ||
    appDocs?.deletedIds?.length > 0 ||
    appDocs?.updated?.length > 0
  ) {
    if (!changes) {
      changes = {};
    }
    changes.appDocs = appDocs;
  }
  if (aggregatedParticipantData) {
    aggregatedParticipantData.docResumes = docResumes;
  }

  return { aggregatedParticipantData, changes };
}

export function aggregateDocs({
  participantDataInitialFull,
  docResumesChanges,
}: {
  participantDataInitialFull: BookingParticipantEditorParticipant;
  docResumesChanges: AppEntityUpdateDescriptor<PRO_AppDocResume>[];
}) {
  const initialDocResumes = participantDataInitialFull?.docResumes ?? [];
  if (docResumesChanges.length) {
    const appDocs: {
      created?: Partial<AppDoc>[];
      updated?: AppEntityUpdatePatch[];
      deletedIds?: string[];
    } = {
      created: docResumesChanges.map((d) => d.created).filter((x) => !!x),
      updated: docResumesChanges.map((d) => d.updated).filter((x) => !!x),
      deletedIds: docResumesChanges.filter((d) => d.deleted).map((x) => x.pk),
    };
    const docResumes: PRO_AppDocResume[] =
      changeDescriptorAggregator.aggregateMany(docResumesChanges, {
        pk: '_id',
        initials: initialDocResumes,
        ignoreErrors: true,
        appLogger,
        logPrefix: 'docResumes',
      });

    return {
      appDocs,
      docResumes,
    };
  } else {
    return {
      appDocs: undefined,
      docResumes: initialDocResumes,
    };
  }
}

function aggregateCore(params: {
  state: BookingParticipantEditorDialogState;
  formValue: BookingParticipantEditorFormModel;
  clubReference: string;
  diveCenterId: string;
  initialFormValue: BookingParticipantEditorFormModel;
  participantDataInitialFull: BookingParticipantEditorParticipant;
  form: UseFormReturn<Partial<BookingParticipantEditorFormModel>>;
}): {
  aggregatedParticipantData: BookingParticipantEditorParticipant;
  changes?: BookingParticipantEditorResultChanges;
} {
  const {
    state,
    formValue,
    clubReference,
    diveCenterId,
    initialFormValue,
    participantDataInitialFull,
    form,
  } = params;
  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const mode = state.mode;

  if (mode === 'edit-participant') {
    const { hasChanges, result } =
      bookingParticipantEditorDialogChangesBuilderEditParticipant.buildChangesResult(
        {
          logContext: `bookingParticipantEditorDialogChangesBuilder (mode=${mode})`,
          participantData: participantDataInitialFull,
          formValue,
          hasFormChanges: formState.isDirty,
          clubReference,
          initialFormValue,
        },
      );
    if (hasChanges) {
      const aggregatedParticipantData: BookingParticipantEditorParticipant =
        buildAggregatedData({ participantDataInitialFull, result });
      return { aggregatedParticipantData, changes: result.changes };
    } else {
      return { aggregatedParticipantData: participantDataInitialFull };
    }
  } else if (mode === 'create-participant') {
    const { hasChanges, result } =
      bookingParticipantEditorDialogChangesBuilderCreateParticipant.buildChangesResult(
        {
          participantData: participantDataInitialFull,
          formValue,
          clubReference,
          diveCenterId,
          initialFormValue,
        },
      );
    if (hasChanges) {
      const aggregatedParticipantData: BookingParticipantEditorParticipant =
        buildAggregatedData({ participantDataInitialFull, result });

      return { aggregatedParticipantData, changes: result.changes };
    } else {
      return { aggregatedParticipantData: participantDataInitialFull };
    }
  } else if (mode === 'edit-diver') {
    const { hasChanges, result } =
      bookingParticipantEditorDialogChangesBuilderEditDiver.buildChangesResult({
        participantData: participantDataInitialFull,
        formValue,
        hasFormChanges: formState.isDirty,
        initialFormValue,
      });

    if (hasChanges) {
      const aggregatedParticipantData: BookingParticipantEditorParticipant =
        buildAggregatedData({ participantDataInitialFull, result });

      return { aggregatedParticipantData, changes: result.changes };
    } else {
      return { aggregatedParticipantData: participantDataInitialFull };
    }
  } else {
    appLogger.error(
      '[useBookingParticipantEditDialogLocalState] Invalid mode:',
      mode,
    );
    throw new Error('Invalid mode');
  }
}

function buildAggregatedData({
  participantDataInitialFull,
  result,
}: {
  participantDataInitialFull: BookingParticipantEditorParticipant;
  result: BookingParticipantEditorResult;
}): BookingParticipantEditorParticipant {
  return {
    ...participantDataInitialFull,
    bookingMember: result.aggregated.finalBookingMember,
    bookingProduct: result.aggregated.finalBookingProduct,
    bookingSessionParticipant: result.aggregated.finalBookingSessionParticipant,
    diver: result.aggregated.finalDiver,
    clubParticipant: result.aggregated.finalClubParticipant,
  };
}
