import { BookingParticipantEditorFormModel } from '@mabadive/app-common-model';
import React, { FC, useCallback, useState } from 'react';
import {
  AppPageBlock,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { confirmDialog } from 'src/business/_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useDiveCenterResume } from 'src/business/club/data/hooks';
import { AppDocViewPanelDiver } from 'src/business/club/modules/app-doc';
import { useClubDiverCardAttributes } from 'src/business/club/modules/club-divers/components/ClubDiverCard';
import { ClubDialogsProvider, ClubDialogsState } from 'src/pages/_dialogs';
import {
  BookingMemberDiverEditFormRHF,
  BookingMemberGeneralEditFormRHF,
  BookingParticipantEditFormRHF,
} from '../../../forms';
import { BookingParticipantDefaultParamsEditFormRHF } from '../../../forms/BookingParticipantDefaultParamsEditForm';
import {
  BookingParticipantEditorDialogState,
  BookingParticipantEditorParticipant,
  BookingParticipantEditorResult,
  ParticipantFormExpansionPanelId,
} from '../../../models';
import {
  BookingParticipantEditorDialogHeader,
  useClubParticipantCardAttributes,
} from '../_common';
import { useDefaultDiveConfigCardAttributes } from '../_common/DefaultDiveConfigCard';
import { BookingParticipantEditorDialogFixedButtonsBar } from './BookingParticipantEditorDialogFixedButtonsBar';
import { BookingParticipantEditorQuickEditActions } from './BookingParticipantEditorQuickEditActions';
import {
  BookingParticipantEditorDialogEditPanel1,
  BookingParticipantEditorDialogEditPanel2,
  BookingParticipantEditorDialogEditPanel3,
} from './panels';
import { BookingParticipantEditorDialogEditPanel4 } from './panels/BookingParticipantEditorDialogEditPanel4';
import { bookingParticipantEditorDialogChangesBuilder } from './services';
import {
  BookingParticipantEditDialogLocalState,
  useBookingParticipantEditDialogLocalState,
} from './useBookingParticipantEditDialogLocalState.hook';

export const BookingParticipantEditorDialog: FC<{
  dialogsState: ClubDialogsState;
  state: BookingParticipantEditorDialogState;
  onClose: () => void;
  onConfirm?: (result: BookingParticipantEditorResult) => void;
  onDelete?: (participant?: BookingParticipantEditorParticipant) => void;
}> = ({ dialogsState, onConfirm, onDelete, state, onClose }) => {
  const defaultExpandedPanel: ParticipantFormExpansionPanelId =
    state.defaultExpandedPanel === 'none'
      ? null
      : state.defaultExpandedPanel ??
        (state.mode === 'edit-diver' ? 'panel1' : 'panel3');

  const [expandedPanel, setExpandedPanel] =
    useState<ParticipantFormExpansionPanelId>(defaultExpandedPanel);

  const expandPanelToogle = useCallback(
    (panelId: ParticipantFormExpansionPanelId) => {
      setExpandedPanel(expandedPanel === panelId ? undefined : panelId);
    },
    [expandedPanel],
  );

  const diveCenterResume = useDiveCenterResume();
  const diveCenterId = diveCenterResume?._id;
  const clubReference = diveCenterResume?.clubReference;
  const {
    participant: initialParticipantData,
  }: { participant?: BookingParticipantEditorParticipant } = state;

  const localState: BookingParticipantEditDialogLocalState =
    useBookingParticipantEditDialogLocalState({
      state,
    });

  const {
    form,
    aggregatedParticipantData,
    changes,
    isPersistInProgress,
    setIsPersistInProgress,
    initialFormValue,
    dialogsStateLocal,
    docResumesChanges,
  } = localState;

  const diver = aggregatedParticipantData?.diver;
  const bookingMember = aggregatedParticipantData?.bookingMember;
  const docResumes = aggregatedParticipantData?.docResumes;
  const inquiryResponses = aggregatedParticipantData?.inquiryResponses;
  const participant = aggregatedParticipantData?.clubParticipant;
  const diveSession = aggregatedParticipantData?.diveSession;

  const { generalAttributes, diverAttributes } = useClubDiverCardAttributes({
    diver,
    bookingMember,
  });

  const { primary: participantCardDiveAttributes } =
    useClubParticipantCardAttributes({
      participant,
      displayMode: 'edit-participant-form-dive-parameters',
    });

  const { primary: participantCardMoreAttributes } =
    useClubParticipantCardAttributes({
      participant,
      displayMode: 'edit-participant-form-more-parameters',
    });

  const defaultDiveConfigCardDiveAttributes =
    useDefaultDiveConfigCardAttributes({
      defaultDiveConfig: diver?.defaultDiveConfig,
    });

  const { register, handleSubmit, watch, formState, control, setValue } = form;

  const hasChanges = formState.isDirty;
  const isValid = formState.isValid;
  const submitForm = useCallback(() => {
    handleSubmit(
      (formValue: BookingParticipantEditorFormModel, event) => {
        const { result, hasChanges } =
          bookingParticipantEditorDialogChangesBuilder.buildChangesResult({
            participantData: state.participant,
            mode: state.mode,
            initialFormValue,
            formValue,
            hasFormChanges: formState.isDirty,
            clubReference,
            diveCenterId,
            docResumesChanges,
          });

        if (hasChanges) {
          onConfirm(result);
        }
        onClose();
      },
      (err) => {
        appLogger.error('Error editing participant', err);
      },
    )();
  }, [
    handleSubmit,
    state.participant,
    state.mode,
    initialFormValue,
    formState.isDirty,
    clubReference,
    diveCenterId,
    docResumesChanges,
    onClose,
    onConfirm,
  ]);

  // {dialogsState.isAnyDialogOpen && (
  //   <ClubDialogsProvider dialogsState={dialogsState}>
  //     {/* DIALOGS : nouveau (ne pas encapsuler l'un dans l'autre, sinon on a 2 divs imbriqués qui font bugger le dialog de paiement par exemple (il ne scroll plus)) */}
  //   </ClubDialogsProvider>
  // )}

  return dialogsStateLocal.isAnyDialogOpen ? (
    <ClubDialogsProvider dialogsState={dialogsStateLocal}>
      {/* DIALOGS : nouveau (ne pas encapsuler l'un dans l'autre, sinon on a 2 divs imbriqués qui font bugger le dialog de paiement par exemple (il ne scroll plus)) */}
    </ClubDialogsProvider>
  ) : !state.isOpen ||
    dialogsState.isAnyDialogOpen ||
    dialogsStateLocal.isAnyDialogOpen ? null : (
    <>
      <div className={`h-full relative ${state.isOpen ? '' : 'hidden'}`}>
        <AppPageContentContainer className="bg-white app-px-content">
          <AppPageBlock className="px-2">
            <div className="py-4 px-2">
              <BookingParticipantEditorDialogHeader diver={diver} />
              <form
                className="mt-4 mb-4"
                noValidate
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
              >
                {state.mode === 'edit-diver' ? (
                  <>
                    <BookingParticipantEditorDialogEditPanel1
                      diver={diver}
                      bookingMember={bookingMember}
                      diverCardAttributes={generalAttributes}
                      expandedPanel={expandedPanel}
                      expandPanelToogle={expandPanelToogle}
                      renderDetails={() => (
                        <BookingMemberGeneralEditFormRHF form={form} />
                      )}
                    />
                    <BookingParticipantEditorDialogEditPanel2
                      diver={diver}
                      bookingMember={bookingMember}
                      diverCardAttributes={diverAttributes}
                      expandedPanel={expandedPanel}
                      expandPanelToogle={expandPanelToogle}
                      renderDetails={() => (
                        <BookingMemberDiverEditFormRHF
                          mode="edit"
                          isDiverWithMember={true}
                          className="pb-28"
                          form={form}
                        />
                      )}
                    />
                    <BookingParticipantEditorDialogEditPanel4
                      className="mt-1"
                      diver={diver}
                      defaultDiveConfigCardDiveAttributes={
                        defaultDiveConfigCardDiveAttributes
                      }
                      expandedPanel={expandedPanel}
                      expandPanelToogle={expandPanelToogle}
                      renderDetails={() => (
                        <BookingParticipantDefaultParamsEditFormRHF
                          className="pb-28"
                          maxWidthMd={false}
                          form={form}
                        />
                      )}
                    />
                  </>
                ) : (
                  <>
                    <BookingParticipantEditorDialogEditPanel1
                      diver={diver}
                      bookingMember={bookingMember}
                      diverCardAttributes={generalAttributes}
                      expandedPanel={expandedPanel}
                      expandPanelToogle={expandPanelToogle}
                      renderDetails={() => (
                        <BookingMemberGeneralEditFormRHF form={form} />
                      )}
                    />
                    <BookingParticipantEditorDialogEditPanel2
                      diver={diver}
                      bookingMember={bookingMember}
                      diverCardAttributes={diverAttributes}
                      expandedPanel={expandedPanel}
                      expandPanelToogle={expandPanelToogle}
                      renderDetails={() => (
                        <BookingMemberDiverEditFormRHF
                          mode="edit"
                          isDiverWithMember={true}
                          className="pb-28"
                          form={form}
                        />
                      )}
                    />
                    <BookingParticipantEditorDialogEditPanel3
                      className="mt-1"
                      diver={diver}
                      participant={participant}
                      diveSession={diveSession}
                      participantCardMoreAttributes={
                        participantCardMoreAttributes
                      }
                      participantCardDiveAttributes={
                        participantCardDiveAttributes
                      }
                      expandedPanel={expandedPanel}
                      expandPanelToogle={expandPanelToogle}
                      renderDetails={() => (
                        <BookingParticipantEditFormRHF
                          maxWidthMd={false}
                          form={form}
                        />
                      )}
                      renderQuickActions={() => (
                        <BookingParticipantEditorQuickEditActions
                          participant={participant}
                          mode={state.mode}
                          setValue={setValue}
                          onClickDelete={() => {
                            confirmDialog
                              .confirm({
                                isTailwind: true,
                                title: 'Supprimer un participant',
                                message:
                                  'Êtes-vous sûr de vouloir supprimer ce participant?',
                                type: 'remove',
                              })
                              .subscribe((confirmed) => {
                                if (confirmed) {
                                  onDelete(state.participant);
                                  onClose();
                                }
                              });
                          }}
                        />
                      )}
                    />
                  </>
                )}
                <input type="submit" className="hidden" />
              </form>
            </div>
            {diver && (
              <AppDocViewPanelDiver
                className="px-4"
                bookingId={bookingMember?.bookingId}
                bookingMemberId={bookingMember?._id}
                clubDiverId={diver?._id}
                docResumes={docResumes}
                dialogsState={dialogsStateLocal}
              />
            )}
          </AppPageBlock>

          <div className="flex-grow"></div>
        </AppPageContentContainer>
        <BookingParticipantEditorDialogFixedButtonsBar
          hasChanges={hasChanges}
          isValid={isValid}
          onSubmit={() => submitForm()}
          onClose={() => {
            onClose();
          }}
        />
      </div>
    </>
  );
};
