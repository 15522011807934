/* eslint-disable @typescript-eslint/no-unused-vars */
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { apiClient } from 'src/_common-browser';

export type FetchAppDocUrlResult = {
  url: string;
};

// le lien est valable 60 minutes, donc on le cache 55mn max
const expiration = 55 * 60 * 1000;

export function useFetchAppDocUrl({
  appDocId,
  enabled,
  triggerFetchIndex,
}: {
  appDocId: string;
  enabled: boolean;
  triggerFetchIndex: number;
}) {
  const query: UseQueryResult<FetchAppDocUrlResult, unknown> = useQuery({
    queryKey: ['/pro/docs', { triggerFetchIndex, appDocId }],
    queryFn: async () => {
      const results = await apiClient
        .get<any>(`/pro/docs/${appDocId}`, {
          authenticate: true,
        })
        .toPromise();
      return results;
    },
    gcTime: expiration, // on garde ça en cach
    staleTime: expiration, // on ne rafraichit pas au rechargement
    refetchOnWindowFocus: false,
    refetchInterval: expiration, // on ne rafraichit que quand le lien expire
    enabled,
  });
  const { isLoading: loadingInProgress, data, refetch } = query;

  return {
    data,
    refetch,
    loadingInProgress,
  };
}
